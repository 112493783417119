import * as React from "react"
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

const KartleggingPage = () => {
    const {
        dtm,
        dsm,
        hoydekurver,
        multispektral,
        ortofoto,
        punktsky,
        volumberegninger,
        // data
    } = useStaticQuery(graphql`
        {
            dsm: file(relativePath: { eq: "kartlegging/dsm.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            dtm: file(relativePath: { eq: "kartlegging/dtm.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            hoydekurver: file(relativePath: { eq: "kartlegging/hoydekurver.png" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            multispektral: file(relativePath: { eq: "kartlegging/multispektralt.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            ortofoto: file(relativePath: { eq: "kartlegging/ortofoto.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            punktsky: file(relativePath: { eq: "kartlegging/punktsky.png" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            volumberegninger: file(relativePath: { eq: "kartlegging/volumberegninger.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            #            data: markdownRemark(frontmatter: {slug: {eq: "bransjer/landbruk"}}) {
            #                id
            #                frontmatter {
            #                    subTitle
            #                    title
            #                    bruksomrader
            #                    girdegbedre
            #                }
            #                html
            #            },
        }
    `)

    return <PageLayout>
        <Seo title={"Kartlegging"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Kartlegging
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <p>
                        Vi utfører kartlegging som kan benyttes til ulike formål ifm planlegging av utvikling av areal, byggeaktiviteter, mv., men også kartlegging som benyttes til landbruksformål. Kartlegging Romvesen AS kan levere er bl.a.:
                    </p>
                </div>
            </div>
        </div>
        <div className="relative bg-secondary-light pt-8 pb-32 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    DTM – Terrengmodeller
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Terrengmodeller er overflatemodeller der vi har klassifisert og filtrert bort alle konstruksjoner og vegetasjon fra den innsamlede punktskyen, slutt resultatet blir presentert som en utjevnet terrengmodell. DTM leveres georefererte med nøyaktighet ned til 2-3 cm, samt kan leveres med oppløsninger under 1 cm.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={dtm.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    DSM – Overflatemodeller
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    En overflatemodell er en ren 3D modell av overflaten på det kartlagte området, her er både vegetasjon og konstruksjoner inkludert. DSM leveres georefererte med nøyaktighet ned til 2-3 cm, samt kan leveres med oppløsninger under 1 cm.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={dsm.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Høydekurver
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Ved hjelp av våre klassifiserte terrrengmodeller kan vi produsere klassiske høydekurve kart (kotekart) i alle ønskelige oppløsninger til bruk i dokumentasjon, kartløsninger eller pr materiell.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={hoydekurver.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Multispektrale kart
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Ved hjelp av våre dronesystemer kan vi raskt kartlegge store landbruksområder og skogsområder, som etter prosessering danner grunnlaget for moderne presisjons landbruk. Vi utfører også optiske klorofyllmålinger for å avdekke tilstanden til plantecellene på åkeren, noe som dermed danner grunnlag for et detaljert avlingskart. Opplysningene som kommer fram kan gi grunnlag for å ta jordprøve av ulike områder av åkeren. Dataene kan også brukes til variabel gjødsling og såing for å få bedre avling. Dronedataene er også et godt utgangspunkt for å lage et plantehelse-kart, som grunnlag for inspeksjon av mulige problemområder.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={multispektral.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Ortofoto
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Georeferert kart der vertikale flater på konstruksjoner og terreng er gjengitt vinkelrett i forhold til overflaten og tilsvarer da et kart som er i samme skala. Ortofoto brukes ofte som underlag for å visualisere plandata. Veitraséer, nye høyspentlinjer eller bygninger og annet kan presenteres på en lettforståelig måte. Det digitale bildet tilpasses en modell av terrenget. På den måten får hvert bildeelement (pixel) en kjent posisjon både i lengde, bredde og høyde. Posisjonsdata for kameraet og perspektivet på bildet tas også med i beregningen for å få en riktig avbildning av terrenget. Kan leveres i oppløsninger ned til 1 cm.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={ortofoto.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Punktskyer
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Punktskyer er ferskvare, desse danner grunnlaget for prosjektering og er råmaterialet vi bruker for utarbeiding av våre kartleggings produkter. Vi leverer fotorealistiske høyoppløste punktskyer utarbeidet etter kravspesifikasjon fra kunde. Punktskyen kan videre klassifiseres etter ønske fra kunde. Klassifiseringen kan for eksempel kategorisere innhold i f.eks. bygninger, bruer, veier, vegetasjon, anlegg, strømlinjer, strømlinjeinstallasjoner, store steiner og vann. En klassifisert punktsky kan også brukes som datagrunnlag for modellering. Alle punktskyer leveres med georeferert nøyaktighet nedtil 2 cm.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={punktsky.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Volumberegninger
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Nøyaktige volumberegninger og oppmålinger av råmateriale/terreng gjøres effektivt basert på våre genererte punktskyer.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={volumberegninger.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default KartleggingPage